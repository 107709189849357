body {
   font-family: 'proxima nova', sans-serif !important;
}

.align-to-logotype {
  margin-top: 0;
  margin-left: 61px;
}

.align-to-ball {
  margin-top: 0;
  margin-left: 1px;
}

@media (min-width: 767px) {
  .globe-bg {
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: flex-start;
    background-image: url('./globe-bg-sm.jpg');
    background-size: contain;
    background-position: bottom left;
    background-repeat: no-repeat;
    background-color: black;
    color: white;
    padding: 0;
    margin: 0;
    max-width: 100%;
  }

  .globe-bg-2 {
    width: 100vw;
    height: 65vh;
    display: flex;
    align-items: flex-start;
    background-image: url('./globe-bg-sm.jpg');
    background-size: contain;
    background-position: bottom left;
    background-repeat: no-repeat;
    background-color: black;
    color: white;
    padding: 0;
    margin: 0;
    max-width: 100%;
  }

  .logo-stuff {
    margin-right: default !important;
    margin-left: 50vw  !important;
    margin: auto;
  }
}


/* Add a black background color to the top navigation */
.topnav {
  background-color: black;
  overflow: hidden;
}

/* Style the links inside the navigation bar */
.topnav a {
  float: right;
  color: white !important;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
  font-size: 18px;

}


/* Change the color of links on hover */
.topnav a:hover {
  background-color: #ddd;
  color: black;
}


/* Add a color to the active/current link */
.topnav a.active {
  background-color: #4CAF50;
  color: white;
}

/*job listing area*/
.job_listing_area {
    background: #F5F7FA;
    padding-bottom: 80px;
}

.job_listing_area .job_lists {
    margin-top: 50px;
}

.job_listing_area .job_lists .single_jobs {
    padding: 30px;
    margin-bottom: 12px;
    border: 1px solid transparent;
    -webkit-transition: 0.3s;
    -moz-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
}

.job_listing_area .job_lists .single_jobs .jobs_left .thumb {
    float: left;
    width: 82px;
    height: 82px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    padding: 15px;
    background: #F5F7FA;
    margin-right: 25px;
    border: 1px solid #F0F0F0;
}

.job_listing_area .job_lists .single_jobs .jobs_left .jobs_conetent {
    float: left;
}

.job_listing_area .job_lists .single_jobs .jobs_left .jobs_conetent h4 {
    font-size: 24px;
    margin-bottom: 10px;
    font-weight: 400;
    -webkit-transition: 0.3s;
    -moz-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
    color: #191d34;
    font-family: "proxima nova", sans-serif !important;
}

.job_listing_area .job_lists .single_jobs .jobs_left .jobs_conetent .links_locat .location {
    margin-right: 50px;
    color: #191d34;
}

.job_listing_area .job_lists .single_jobs .jobs_left .jobs_conetent .links_locat .location p {
    margin-bottom: 0;
    font-size: 16px;
    color: #191d34 !important;
}

.job_listing_area .job_lists .single_jobs .jobs_left .jobs_conetent .links_locat .location p {
    margin-right: 7px;
    color: #7A838B;
    font-family: "proxima nova", sans-serif !important;
}

.job_listing_area .job_lists .single_jobs .jobs_left .jobs_conetent .links_locat .location p i {
    margin-right: 7px;
    color: #7A838B;
    font-weight: 900;
}

.job_listing_area .job_lists .single_jobs .jobs_right .apply_now a.boxed-btn3 {
    padding: 9px 27px 9px 27px;
    font-size: 14px;
}

.job_listing_area .job_lists .single_jobs .jobs_right .date {
    text-align: right;
    margin-top: 10px;
}

.job_listing_area .job_lists .single_jobs .jobs_right .date p {
    margin-bottom: 0;
    font-size: 14px;
    font-style: italic;
    color: #7A838B;
}

.job_details_header{
  width: 100%;
}

.job_details_area .single_jobs {
    padding: 30px;
    border-bottom: 1px solid #EAEAEA;
    -webkit-transition: 0.3s;
    -moz-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
}

.job_details_area .single_jobs .jobs_left .thumb {
    float: left;
    width: 82px;
    height: 82px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    padding: 15px;
    background: #F5F7FA;
    margin-right: 25px;
    border: 1px solid #F0F0F0;
}

.job_details_area .single_jobs .jobs_left .jobs_conetent {
    float: left;
}

.job_details_area .single_jobs .jobs_left .jobs_conetent h4 {
    font-size: 24px;
    margin-bottom: 10px;
    font-weight: 400;
    -webkit-transition: 0.3s;
    -moz-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
    color: #191d34;
}

.job_details_area .single_jobs .jobs_left .jobs_conetent .links_locat .location {
    margin-right: 50px;
}

.job_details_area .single_jobs .jobs_left .jobs_conetent .links_locat .location p {
    font-family: 'proxima nova', sans-serif;
}

.job_details_area .descript_wrap {
    padding: 40px;
}

.job_details_area .descript_wrap .single_wrap {
    margin-bottom: 30px;
}

.job_details_area .apply_job_form {
    margin-top: 30px;
    padding: 40px;
}

@media (max-width: 991px) and (min-width: 768px)
.job_details_area .job_summary {
    margin-top: 30px;
}

.job_details_area .job_summary {
    background: #fff;
}

@media (max-width: 991px) and (min-width: 768px)
.job_details_area .job_summary {
    margin-top: 30px;
}

.job_details_area .job_summary .job_content {
    padding: 40px;
}

.job_details_area .job_summary .summary_header {
    border-bottom: 1px solid #EAEAEA;
    padding: 52px;
}

.job_details_area .job_summary .summary_header h3 {
    font-size: 24px;
    color: #001D38;
    font-weight: 400;
}

.job_details_area .descript_wrap .single_wrap h4 {
    font-size: 20px;
    font-weight: 500;
    color: #001D38;
    margin-bottom: 25px;
    font-family: 'proxima nova', sans-serif;
}

.job_details_area .job_summary .job_content {
    padding: 40px;
}

.job_details_area .job_summary .job_content ul li {
    font-size: 16px;
    font-weight: 400;
    color: #7A838B;
    line-height: 38px;
    padding-left: 18px;
    position: relative;
}

.job_details_area .descript_wrap .single_wrap ul li {
    font-size: 16px;
    line-height: 32px;
    color: #7A838B;
    font-weight: 400;
    position: relative;
    padding-left: 25px;
}

.job_details_area .descript_wrap .single_wrap h4 {
    font-size: 20px;
    font-weight: 500;
    color: #001D38;
    margin-bottom: 25px;
}

.job_details_area .descript_wrap .single_wrap ul li::before {
    position: absolute;
    left: 0;
    top: 0;
    width: 7px;
    height: 7px;
    background: #7A838B;
    content: '';
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    top: 12px;
}

.job_details_area .apply_job_form {
    margin-top: 30px;
    padding: 40px;
}

.job_details_area .apply_job_form h4 {
    font-size: 24px;
    font-weight: 500;
    margin-bottom: 30px;
}

.job_details_area .apply_job_form .input_field input, .job_details_area .apply_job_form .input_field textarea {
    height: 60px;
    border: 1px solid #E8E8E8;
    width: 100%;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    padding-left: 20px;
    margin-bottom: 20px;
}

.job_details_area .apply_job_form .input-group {
    width: 100%;
    height: 60px;
    border-radius: 5px !important;
    margin-bottom: 20px;
    border: 1px solid #ddd;
}

.job_details_area .apply_job_form .input-group button {
    background: transparent;
    border: none;
    font-size: 16px;
    color: #7A838B !important;
    padding-left: 20px;
    margin-right: 5px;
}

.job_details_area .apply_job_form .input-group .custom-file {
    margin-bottom: 0;
    height: 60px;
    border: none;
}

.job_details_area .apply_job_form .input-group .custom-file-input {
    height: 100%;
}

.job_details_area .apply_job_form .input-group .custom-file-label {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1;
    height: 100%;
    padding: 0;
    line-height: 60px;
    color: #7A838B;
    background-color: transparent;
    border-radius: 0;
    border: none;
}

.custom-file-input:lang(en)~.custom-file-label::after {
    content: none !important;
}

.custom-file-label::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    height: calc(calc(2.25rem + 2px) - 1px * 2);
    padding: .375rem .75rem;
    line-height: 1.5;
    color: #495057;
    border-radius: 0 .25rem .25rem 0;
}

.job_details_area .apply_job_form .input_field textarea {
    height: 188px !important;
    padding: 20px;
}

.job_details_area .apply_job_form .input_field input, .job_details_area .apply_job_form .input_field textarea {
    height: 60px;
    border: 1px solid #E8E8E8;
    width: 100%;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    padding-left: 20px;
    margin-bottom: 20px;
}

.job_details_area .job_sumary .summary_header {
    border-bottom: 1px solid #EAEAEA;
    padding: 40px;
}

.job_details_area .job_summary .job_content ul li::before {
    position: absolute;
    width: 8px;
    height: 8px;
    border: 1px solid #AAB1B7;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    left: 0;
    content: '';
    top: 16px;
}

h1, h2, h3, h4, h5, h6 {
    line-height: 1.2em;
}

h1, h2, h3, h4, h5 {
    font-family: "proxima nova", sans-serif !important;
    color: #191d34;
}

.h3, h3 {
    font-size: 1.75rem;
}

.h4, h4 {
    font-size: 1.5rem;
}

img {
    vertical-align: middle;
    border-style: none;
}

.white-bg {
    background: #ffffff;
}

.justify-content-between {
    -webkit-box-pack: justify!important;
    -ms-flex-pack: justify!important;
    justify-content: space-between!important;
}

.d-flex {
    display: -webkit-box!important;
    display: -ms-flexbox!important;
    display: flex!important;
}

.job_details_area .single_jobs .jobs_left .jobs_conetent .links_locat .location p i {
    margin-right: 7px;
    color: #7A838B;
    font-weight: 900;
}

.fa {
    display: inline-block;
    font-family: FontAwesome;
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

/*container*/
@media (min-width: 768px)
.container {
    max-width: 720px;
}

.container {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

*, ::after, ::before {
    box-sizing: border-box;
}

/*row align*/
.align-items-center {
    -webkit-box-align: center!important;
    -ms-flex-align: center!important;
    align-items: center!important;
}

.justify-content-between {
    -webkit-box-pack: justify!important;
    -ms-flex-pack: justify!important;
    justify-content: space-between!important;
}

.row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
}

/*column sizes*/
.col, .col-1, .col-10, .col-11, .col-12, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-auto, .col-lg, .col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7,
.col-lg-8, .col-lg-9, .col-lg-auto, .col-md, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-auto, .col-sm, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4,
.col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-auto, .col-xl, .col-xl-1, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-auto {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

@media (min-width: 768px)
.col-md-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
}

/*section title*/
@media (max-width: 991px) and (min-width: 768px)
.section_title h3 {
    font-size: 36px;
    line-height: 42px;
}

.section_title h3 {
    font-size: 38px;
    font-weight: 400;
    color: #001D38;
    position: relative;
    z-index: 0;
    margin-bottom: 0;
}

.jl-area{
  padding-top: 100;
  background: #F5F7FA
}

/*a, button, p*/
div {
    display: block;
}

a, button {
    color: #1F1F1F;
    outline: medium none;
}

a, .button {
    -webkit-transition: 0.3s;
    -moz-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
}

a {
    color: #007bff;
    text-decoration: none;
    background-color: transparent;
    -webkit-text-decoration-skip: objects;
}

p {
    font-size: 16px;
    font-weight: 400;
    line-height: 28px;
    color: #7A838B;
    margin-bottom: 13px;
    font-family: "proxima nova", sans-serif !important;
    margin-top: 0;
    margin-bottom: 1rem;
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
}

.boxed-btn3 {
    background: #00D363;
    color: #fff;
    display: inline-block;
    padding: 13px 29px 13px 29px;
    font-family: "proxima nova", sans-serif !important;
    font-size: 16px;
    font-weight: 500;
    border: 0;
    border: 1px solid transparent;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    text-align: center;
    color: #fff !important;
    text-transform: capitalize;
    -webkit-transition: 0.3s;
    -moz-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
    cursor: pointer;
}

.fa {
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.fa-map-marker:before {
    content: "\f041";
    color: #AAB1B7;
    font-weight: 900;
}

.fa-clock-o:before {
    content: "\f017";
    color: #AAB1B7;
}

.fa-cloud-upload:before {
    content: "\f0ee";
}

ul {
    margin: 0px;
    padding: 0px;
}

li {
    list-style: none;
}

.input-group {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    width: 100%;
}

.input-group-prepend {
    margin-right: -1px;
}

.founder_desc{
    font-size: 16px;
    font-weight: 400;
    line-height: 28px;
    color: #7A838B;
}

.email{
  color: #007bff !important;
}

.job_sum_det{
  color: #AAB1B7 !important;
}

.icon-text{
  font-family: 'proxima nova', sans-serif !important;
  color: #7A838B;
}

/*for mobile*/
@media (max-width: 767px){
  .globe-bg {
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    background-color: black;
    background-size: contain;
    background-position: bottom left;
    background-repeat: no-repeat;
    color: white;
    padding: 0;
    margin: 0;
    max-width: 100%;
  }

  .globe-bg-2 {
    width: 100vw;
    height: 65vh;
    display: flex;
    align-items: center;
    background-color: black;
    background-size: contain;
    background-position: bottom left;
    background-repeat: no-repeat;
    color: white;
    padding: 0;
    margin: 0;
    max-width: 100%;
  }

  .job_listing_area .job_lists .single_jobs {
      display: block !important;
  }

  .job_listing_area .job_lists .single_jobs .jobs_left {
    display: block !important;
    overflow: hidden;
  }

  .job_listing_area .job_lists .single_jobs .jobs_left .jobs_conetent h4 {
    margin-top: 15px;
  }

  .job_listing_area .job_lists .single_jobs .jobs_left .jobs_conetent .links_locat {
    display: block !important;
  }

  .job_listing_area .job_lists .single_jobs .jobs_left .jobs_conetent .links_locat .location {
    margin-right: 10px;
  }


  .job_listing_area .job_lists .single_jobs .jobs_right .apply_now {
      margin: 10px 0;
  }

  .job_listing_area .job_lists .single_jobs .jobs_right .date {
    text-align: left;
  }

  .job_details_area .single_jobs {
    display: block !important;
  }

  .job_details_area .single_jobs .jobs_left {
    display: block !important;
    overflow: hidden;
  }

  .job_details_area .single_jobs .jobs_left .jobs_conetent h4 {
    margin-top: 15px;
  }
}
